import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import BasicTable from "./BasicTable";
import {fetchViaPost} from "../helpers/fetchViaPost";
import StatIcon from "@mui/icons-material/AlignHorizontalLeftOutlined";
import OtherMarketsIcon from "@mui/icons-material/LaunchOutlined";
import {IconButton} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {styled} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

function getProgressBar(val1, val2, color, position) {
  return <LinearProgress
    variant="buffer"
    value={(position === "left") ? 100 - val1 : val1}
    valueBuffer={val2}
    sx={(position === "left") ? sxLinearProgressRight(color) : sxLinearProgressLeft(color)}
  />;
}

function sxLinearProgressLeft(bg) {
  return {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "lightgrey",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: bg,
    }
  }
};

function sxLinearProgressRight(bg) {
  return {
    "& .MuiLinearProgress-colorPrimary":
      {
        backgroundColor: bg,
      }
    ,
    "& .MuiLinearProgress-barColorPrimary":
      {
        backgroundColor: "lightgrey",
      }
  }
};

function calcStats(jsonData, field) {
  function getNumber(data) {
    try {
      return Number(data[field]["value"]);
    } catch (ex) {
      throw ex;
    }
  }

  let num = 0;
  num += getNumber(jsonData[34]) * 1;
  num += getNumber(jsonData[42]) * 1;
  num += getNumber(jsonData[43]) * 0.05;
  num += getNumber(jsonData[44]) * 0.15;
  num += getNumber(jsonData[45]) / 100 * 10;
  num += getNumber(jsonData[49]) * 0.5;
  num += getNumber(jsonData[54]) * 1;
  num += getNumber(jsonData[86]) * 1;
  num += getNumber(jsonData[98]) * 0.1;
  num += getNumber(jsonData[99]) * 0.4;
  num *= 0.0276;
  return num;
}

function CustomTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AsianHandicapMenu({row}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = React.useState(null)
  const [type, setType] = React.useState(false)
  const [shouldGoals, setShouldGoals] = React.useState({
    "home": "Can't be calculated!",
    "away": "Can't be calculated!"
  })
  const [statistics, setStatistics] = React.useState(false)

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const open = Boolean(anchorEl);
  const handleClickStats = (event) => {
    setType(true)
    setTitle(row.localTeam.custom_name + " - " + row.visitorTeam.custom_name)
    var output = [];
    var headers = [];
    fetchStatistics(row);
    setRows(output);
    setAnchorEl(event.currentTarget);
  };
  const handleClickOtherMarkets = (event) => {
    setType(false)
    setTitle(row.localTeam.custom_name + " - " + row.visitorTeam.custom_name)
    fetchData(row.id, row.user.user.id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setHeaders(null);
  };

  const [sidebar, setSidebar] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);

  const showSidebar = () => setSidebar(!sidebar);

  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Item = styled(Paper)(({theme}) => ({
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    textAlign: 'center',
  }));

  async function fetchStatistics(row) {
    const request = await fetchViaPost("statistics", JSON.stringify({
      user_id: "",
      fixture_id: row.id
    }));
    let jsonData = await request.json();
    try {
      var sh = {};
      let getHomeStats = calcStats(jsonData, "home").toFixed(2);
      let getAwayStats = calcStats(jsonData, "away").toFixed(2);
      sh["home"] = getHomeStats;
      sh["away"] = getAwayStats;
      setShouldGoals(sh);
    } catch (ex) {
      //throw ex;
    }
    //let shouldGoalsHome=
    setStatistics(jsonData);
  }

  async function fetchData(id, user_id) {
    const request = await fetchViaPost("calculate-fixture", JSON.stringify({
      user_id: user_id,
      fixture_id: id
    }));
    let jsonData = await request.json();
    const {true_asian_line_over, true_asian_line_under} = jsonData["calcAsianGoallinesFromResultMatrix"];
    const {truebothteamsscore, trueatleastonedoesnotscore} = jsonData["calcBothTeamsToScoreFromResultmatrix"];
    const {trueloss, truewin} = jsonData["calcAsianHandicapSidesFromResultmatrix"];
    const distrib = jsonData["distribution"];
    var output = [];
    var headers = [];
    headers.push({title: "Asian goalline", first: "Over", second: "Under"})
    headers.push({title: "Asian handicap", first: "1", second: "2"})
    headers.push({title: "Both teams to score", first: "Yes", second: "No"})
    headers.push({title: "Correct score", first: "", second: ""})
    var marketData = [];
    var startGoalline = 1.25
    var startIndex = 6
    for (let i = 0; i < 10; i++) {
      marketData.push({
        name: startGoalline.toFixed(2),
        first: true_asian_line_over[startIndex].toFixed(2),
        second: true_asian_line_under[startIndex].toFixed(2)
      });
      startGoalline += 0.25;
      startIndex++;
    }
    output.push(marketData)

    var asianHandicap = [];
    asianHandicap.push({name: '-1.5', first: truewin[8].toFixed(2), second: trueloss[8].toFixed(2)});
    asianHandicap.push({name: '-1.25', first: truewin[9].toFixed(2), second: trueloss[9].toFixed(2)});
    asianHandicap.push({name: '-1.0', first: truewin[10].toFixed(2), second: trueloss[10].toFixed(2)});
    asianHandicap.push({name: '-0.75', first: truewin[11].toFixed(2), second: trueloss[11].toFixed(2)});
    asianHandicap.push({name: '-0.5', first: truewin[12].toFixed(2), second: trueloss[12].toFixed(2)});
    asianHandicap.push({name: '-0.25', first: truewin[13].toFixed(2), second: trueloss[13].toFixed(2)});
    asianHandicap.push({name: '0', first: truewin[14].toFixed(2), second: trueloss[14].toFixed(2)});
    asianHandicap.push({name: '+0.25', first: truewin[15].toFixed(2), second: trueloss[15].toFixed(2)});
    asianHandicap.push({name: '+0.5', first: truewin[16].toFixed(2), second: trueloss[16].toFixed(2)});
    asianHandicap.push({name: '+0.75', first: truewin[17].toFixed(2), second: trueloss[17].toFixed(2)});
    asianHandicap.push({name: '+1.0', first: truewin[18].toFixed(2), second: trueloss[18].toFixed(2)});
    asianHandicap.push({name: '+1.25', first: truewin[19].toFixed(2), second: trueloss[19].toFixed(2)});
    asianHandicap.push({name: '+1.5', first: truewin[20].toFixed(2), second: trueloss[20].toFixed(2)});
    output.push(asianHandicap);

    var bothteamsscore = [];
    bothteamsscore.push({name: '', first: truebothteamsscore.toFixed(2), second: trueatleastonedoesnotscore.toFixed(2)})
    output.push(bothteamsscore)

    var correctScoreData = [];

    function calcToFixed(number) {
      let result = 1 / number;
      if (result < 10)
        return result.toFixed(2)
      if (result < 30)
        return result.toFixed(1)
      return result.toFixed(0);
    }

    correctScoreData.push({
      name: '1:0 - ' + calcToFixed(distrib[1][0]),
      first: '0:0 - ' + calcToFixed(distrib[0][0]),
      second: '0:1 - ' + calcToFixed(distrib[0][1])
    })
    correctScoreData.push({
      name: '2:0 - ' + calcToFixed(distrib[2][0]),
      first: '1:1 - ' + calcToFixed(distrib[1][1]),
      second: '0:2 - ' + calcToFixed(distrib[0][2])
    })
    correctScoreData.push({
      name: '2:1 - ' + calcToFixed(distrib[2][1]),
      first: '2:2 - ' + calcToFixed(distrib[2][2]),
      second: '1:2 - ' + calcToFixed(distrib[1][2])
    })
    correctScoreData.push({
      name: '3:0 - ' + calcToFixed(distrib[3][0]),
      first: '3:3 - ' + calcToFixed(distrib[3][3]),
      second: '0:3 - ' + calcToFixed(distrib[0][3])
    })
    correctScoreData.push({
      name: '3:1 - ' + calcToFixed(distrib[3][1]),
      first: '4:4 - ' + calcToFixed(distrib[4][4]),
      second: '1:3 - ' + calcToFixed(distrib[1][3])
    })
    correctScoreData.push({
      name: '3:2 - ' + calcToFixed(distrib[3][2]),
      first: '5:5 - ' + calcToFixed(distrib[5][5]),
      second: '2:3 - ' + calcToFixed(distrib[2][3])
    })
    correctScoreData.push({
      name: '4:0 - ' + calcToFixed(distrib[4][0]),
      first: '6:6 - ' + calcToFixed(distrib[6][6]),
      second: '0:4 - ' + calcToFixed(distrib[0][4])
    })
    output.push(correctScoreData)
    setRows(output)
    setHeaders(headers);
  }

  let content;

  function drawStat() {
    function printItem(al, val) {
      return <Item sx={al}>
        {val}
      </Item>;
    }

    function getGridRow(homeValue, statValue, awayValue) {
      var start = <>
        <Grid item xs={2}>
          {printItem(alignLeft, homeValue)}
        </Grid>
        <Grid item xs={8}>
          {printItem(alignCenter, statValue)}
        </Grid>
        <Grid item xs={2}>
          {printItem(alignRight, awayValue)}
        </Grid>
      </>;
      var end = <>
        <Grid item xs={6} sx={{marginBottom: '10px'}}>
          <Item sx={{paddingRight: '2px'}}>
            {getProgressBar((homeValue === awayValue && homeValue === 0) ? 0 : ((100 * homeValue) / (homeValue + awayValue)), 100, (homeValue > awayValue) ? "green" : "red", "left")}
          </Item>
        </Grid>
        <Grid item xs={6} sx={{marginBottom: '10px'}}>
          <Item sx={{paddingLeft: '2px'}}>
            {getProgressBar((homeValue === awayValue && homeValue === 0) ? 0 : ((100 * awayValue) / (homeValue + awayValue)), 100, (homeValue < awayValue) ? "green" : "red", "right")}
          </Item>
        </Grid>
      </>;
      return [start, end];
    }

    function loopGrid() {
      var gridRow = [];
      Object.keys(statistics).forEach(function (it) {
        gridRow.push(getGridRow(statistics[it].home.value, statistics[it].title, statistics[it].away.value))
      })
      return gridRow;
    }

    return <Grid container>
      {loopGrid()}
      {<Grid item xs={12} sx={{fontWeight: 'bold'}}>
        Should goals
      </Grid>}
      {<Grid item xs={6} sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#005ef3',
        overflowX: 'hidden',
        textOverflow: "ellipsis",
        direction: "rtl"
      }}>
        {shouldGoals["home"]}
      </Grid>}
      {<Grid item xs={6} sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#005ef3',
        overflowX: 'hidden',
        textOverflow: "ellipsis",
        direction: "rtl"
      }}>
        {shouldGoals["away"]}
      </Grid>}
    </Grid>;
  }

  function getTemperature() {
    let weather = row["weather_report"];
    if (weather === null)
      return <DoNotDisturbAltIcon/>;
    let weatherElement = weather["temperature_celcius"];
    if (weatherElement === null)
      return <DoNotDisturbAltIcon/>;
    return weatherElement["temp"];
  }

  function getTempType() {
    let rowElement = row["weather_report"];
    if (rowElement === null)
      return <DoNotDisturbAltIcon/>;
    return rowElement["type"];
  }

  if (type) {
    /*    console.clear();
        console.log(row);
        console.log(row["league"]["name"]);
        console.log(row["scores"]["ft_score"]);
        console.log(row["weather_report"]["icon"]);
        console.log(row["weather_report"]["temperature_celcius"]["temp"]);
        console.log(row["details"]["date"]);*/
    var alignLeft = {textAlign: 'left', fontSize: '0.8em'};
    var alignCenter = {textAlign: 'center'};
    var alignRight = {textAlign: 'right', fontSize: '0.8em'};
    let rowGridClass = {
      textAlign: 'center',
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      verticalAlign: "middle"
    };
    content =
      <Box component="section" sx={{p: 2, border: '0.5px dashed grey', textAlign: 'center'}}>
        <Box sx={{'& > :not(style)': {m: 1}, textAlign: 'right'}}>
          <Fab size="small" color="none" aria-label="add" onClick={handleClose}>
            <CloseIcon/>
          </Fab>
        </Box>
        <Grid container columns={{xs: 6, sm: 12, md: 12}}>
          {<Grid item xs={6} sx={{textAlign: 'center'}}>
            <div>
              <img style={{verticalAlign: "middle", paddingRight: '20px', height: "3em"}}
                   src={row["localTeam"]["data"]["logo_path"]}/>
              <h2 style={{display: 'inline-block'}}>{row["localTeam"]["custom_name"]}</h2>
            </div>
          </Grid>}
          {<Grid item xs={6} sx={{textAlign: 'center'}}>
            <div>
              <h2 style={{display: 'inline-block'}}>{row["visitorTeam"]["custom_name"]}</h2>
              <img style={{verticalAlign: "middle", paddingRight: '20px', height: "3em"}}
                   src={row["visitorTeam"]["data"]["logo_path"]}/>
            </div>
          </Grid>}
          {<Grid item xs={12} sx={{
            textAlign: 'center',
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            verticalAlign: "middle",
            marginTop: "2em"
          }}>
            <EmojiEventsIcon style={{color: "#005ef3"}}/>: {row["league"]["name"]}
          </Grid>}
          {<Grid item xs={12} sx={rowGridClass}>
            <EventAvailableIcon style={{color: "#005ef3"}}/>: {row["details"]["date"].toUTCString()}
          </Grid>}
          {/*{<Grid item xs={12} sx={rowGridClass}>*/}
          {/*  <LocationOnIcon/>: {row["league"]["name"]}*/}
          {/*</Grid>}*/}
          {<Grid item xs={12} sx={rowGridClass}>
            <ThermostatIcon style={{color: '#005ef3'}}/>: {getTemperature()}°C ({getTempType()})
          </Grid>}
          {<Grid item xs={12} sx={rowGridClass}>
            <SportsScoreIcon style={{color: '#005ef3'}}/>: {row["scores"]["ft_score"]} ({row["scores"]["ht_score"]})
          </Grid>}
        </Grid>
        <Box sx={{width: '100%', marginTop: "2em"}}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab label="Table" {...a11yProps(0)} disabled={true}/>
              <Tab label="Chart" {...a11yProps(1)}/>
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            ...
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {drawStat()}
          </CustomTabPanel>
        </Box>
      </Box>
  } else {
    content = <BasicTable title={title} headers={headers} rows={rows}/>;
  }
  return (
    <div>
      <IconButton
        color="info"
        title="Stats"
        size="small"
        onClick={handleClickStats}>
        <StatIcon/>
      </IconButton>
      <IconButton
        color="primary"
        size="small"
        title="Other markets"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOtherMarkets}
      >
        <OtherMarketsIcon/>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableRipple
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            minWidth: 300,
          },
        }}
      >
        <MenuItem disableRipple sx={[{'&:hover': {backgroundColor: 'transparent'}}]}>{content}</MenuItem>
      </Menu>
    </div>
  );
}
